import { useLocation, useNavigate } from "react-router-dom";
import { getBEMClasses } from "../../helpers/bemHelper";
import { EmailSubscribe } from "../EmailSubscribe";
import Logo from "../../assets/footer-logo.png";
import Instagram from "../../assets/icons/instagram.svg";
import Discord from "../../assets/icons/discord.svg";
import Twitter from "../../assets/icons/twitter.svg";
import LinkedIn from "../../assets/icons/linkedin.svg";
import Facebook from "../../assets/icons/facebook.svg";
import Spotify from "../../assets/icons/spotify.svg";
import { useIsTablet } from "../../hooks/useIsMobile";
import { openLinkInNewTab } from "../../helpers/linksHelper";
import "./styles.css";

const baseClass = getBEMClasses("footer");

const Footer = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isTablet = useIsTablet();

  const isLinkSelected = (link) => {
    return pathname.includes(link);
  };
  const isEmptyFooter = pathname.includes("consign-with-us");

  const renderNavigation = () => {
    return (
      <div className={baseClass("navigation-column")}>
        <div
          className={baseClass(
            "navigation-link",
            pathname === "/" && "selected"
          )}
          onClick={() => navigate("")}
        >
          Home
        </div>
        <div
          className={baseClass(
            "navigation-link",
            isLinkSelected("about-us") && "selected"
          )}
          onClick={() => navigate("about-us")}
        >
          About Us
        </div>
        <div
          className={baseClass(
            "navigation-link",
            isLinkSelected("drops") && "selected"
          )}
          onClick={() => navigate("drops")}
        >
          Drops
        </div>
        <div
          className={baseClass(
            "navigation-link",
            isLinkSelected("platform") && "selected"
          )}
          onClick={() => navigate("platform")}
        >
          Platform
        </div>
        <div
          className={baseClass(
            "navigation-link",
            isLinkSelected("consign-with-us") && "selected"
          )}
          onClick={() => navigate("consign-with-us")}
        >
          Consign With Us
        </div>
      </div>
    );
  };

  const renderResources = () => {
    return (
      <div className={baseClass("resources-column")}>
        <div className={baseClass("column-title")}>Resources</div>
        <div
          className={baseClass(
            "resources-link",
            isLinkSelected("blogs") && "selected"
          )}
          onClick={() => navigate("blogs")}
        >
          Blog
        </div>
        {/* 
        <div
          className={baseClass(
            "resources-link",
            isLinkSelected("press") && "selected"
          )}
          onClick={() => navigate("press")}
        >
          Press
        </div>
        */}

        <div
          className={baseClass(
            "resources-link",
            isLinkSelected("fee-schedule") && "selected"
          )}
          onClick={() => navigate("fee-schedule")}
        >
          Fee Schedule
        </div>

        <div
          className={baseClass("resources-link")}
          onClick={() =>
            openLinkInNewTab("https://support.liquidmarketplace.io/hc/en-us")
          }
        >
          Help Center
        </div>
      </div>
    );
  };

  const renderContacts = () => {
    return (
      <>
        <div className={baseClass("column-title")}>Contact Us</div>
        <div
          className={baseClass(
            "contact-us-link",
            isLinkSelected("media-inquiries") && "selected"
          )}
          onClick={() => navigate("media-inquiries")}
        >
          Media Inquiries
        </div>
        <div
          className={baseClass("contact-us-link")}
          onClick={() => {
            navigate("about-us");
            setTimeout(() => {
              const element = document.getElementById("join-us");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }, 200);
          }}
        >
          Careers
        </div>
      </>
    );
  };

  const renderSocials = () => {
    return (
      <div className={baseClass("contact-us-socials")}>
        <img
          src={Instagram}
          className={baseClass("contact-us-social-icon")}
          onClick={() => openLinkInNewTab("https://hubs.ly/Q01cK0f50")}
        />
        <img
          src={Discord}
          className={baseClass("contact-us-social-icon")}
          onClick={() => openLinkInNewTab("https://hubs.ly/Q01cK2Dq0")}
        />
        <img
          src={Twitter}
          className={baseClass("contact-us-social-icon")}
          onClick={() => openLinkInNewTab("https://hubs.ly/Q01cK0YN0")}
        />
        <img
          src={LinkedIn}
          className={baseClass("contact-us-social-icon")}
          onClick={() => openLinkInNewTab("https://hubs.ly/Q01cK0JJ0")}
        />
        <img
          src={Facebook}
          className={baseClass("contact-us-social-icon")}
          onClick={() => openLinkInNewTab("https://hubs.ly/Q01cK0PR0")}
        />
        <img
          src={Spotify}
          className={baseClass("contact-us-social-icon")}
          onClick={() =>
            openLinkInNewTab("https://merch.liquidmarketplace.io/")
          }
        />
      </div>
    );
  };

  return isEmptyFooter ? (
    <div className={baseClass("empty")}>
      <div className={baseClass("divider")} />
      <div className={baseClass("name")}>©2022 Liquid MarketPlace Inc.</div>
    </div>
  ) : (
    <div className={baseClass()}>
      <div className={baseClass("content")}>
        <div className={baseClass("logo-email-column")}>
          {!isTablet && <img src={Logo} className={baseClass("logo")} />}
          <EmailSubscribe />
        </div>
        {isTablet ? (
          <>
            <div className={baseClass("links")}>
              {renderNavigation()}
              <div>
                {renderResources()}
                <div className={baseClass("contact-us-column")}>
                  {renderContacts()}
                </div>
              </div>
            </div>
            {renderSocials()}
          </>
        ) : (
          <div className={baseClass("links")}>
            {renderNavigation()}
            {renderResources()}
            <div className={baseClass("contact-us-column")}>
              {renderContacts()}
              {renderSocials()}
            </div>
          </div>
        )}
      </div>
      <div className={baseClass("bottom-content")}>
        <div className={baseClass("company")}>
          ©2022 Liquid MarketPlace Inc.
        </div>
        <div className={baseClass("policy-links")}>
          <div
            className={baseClass("policy-link")}
            onClick={() => navigate("privacy-policy")}
          >
            Privacy Policy
          </div>
          <div
            className={baseClass("policy-link")}
            onClick={() => navigate("terms-of-use")}
          >
            Terms of Use
          </div>
        </div>
      </div>
    </div>
  );
};

export { Footer };
